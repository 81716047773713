import { Injectable, OnDestroy, inject } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { ApplicationInsights, ICustomProperties, SeverityLevel } from '@microsoft/applicationinsights-web';
import { Subscription } from 'rxjs';
import { AppService } from '../app.service';
import { getEnv } from '../utils/getEnv';

/**
 *
 */
// TODO: Add Angular decorator.
// TODO: Add Angular decorator.
@Injectable()
export class TelemetryService implements OnDestroy {
  subscriptions: Subscription[] = [];
  private appInsights!: ApplicationInsights;
  private router = inject(Router);
  private app = inject(AppService);

  private lastEvent: NavigationEnd | null = null;

  /**
   *
   */
  init() {
    const instrumentationKey = getEnv('instrumentationKey');
    if (instrumentationKey && location.hostname !== 'localhost') {
      if (!this.appInsights) {
        // Setup azure telemetry
        this.appInsights = new ApplicationInsights({
          config: {
            instrumentationKey,
            maxBatchInterval: 0,
            disableFetchTracking: false,
          },
        });
      }
      this.appInsights.loadAppInsights();

      // Track page views
      this.subscriptions.push(
        this.router.events.subscribe((event: Event) => {
          if (event instanceof NavigationEnd) {
            const url = event.urlAfterRedirects;
            this.appInsights?.trackPageView({
              name: document.title,
              refUri: this.lastEvent?.url ?? document.referrer ?? '',
              uri: url,
              properties: {
                application: this.app.getApplicationName(),
              },
            });
            this.lastEvent = event;
          }
        }),
      );
    }
  }

  logEvent(name: string, event: ICustomProperties) {
    event = { ...event, application: this.app.getApplicationName() };
    this.appInsights?.trackEvent({ name, properties: event });
  }

  logError(error: Error) {
    // Log an exception that you have caught
    this.appInsights?.trackException(
      { exception: error, severityLevel: SeverityLevel.Error },
      { application: this.app.getApplicationName() },
    );
  }

  ngOnDestroy() {
    this.subscriptions.filter(s => !!s && !s.closed).forEach(s => s.unsubscribe());
  }
}
