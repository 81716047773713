@if (!primaryApp()) {
  <section>
    @for (application of applications(); track application.applicationID) {
      <a
        [routerLink]="[application.hasAccess === true || isAdministrator() ? application.applicationUrl : null]"
        target="_self"
        [attr.aria-label]="application.applicationName"
        [title]="application.description"
        [attr.disabled]="application.hasAccess !== true ? true : null"
      >
        <mat-card>
          <mat-card-header>
            <div matCardAvatar [ngSwitch]="application.applicationID">
              @switch (application.applicationID) {
                @case (1) {
                  <app-icon-app-energy></app-icon-app-energy>
                }
                @case (2) {
                  <app-icon-app-advise></app-icon-app-advise>
                }
                @case (3) {
                  <app-icon-app-sense></app-icon-app-sense>
                }
                @case (4) {
                  <app-icon-app-facility></app-icon-app-facility>
                }
                @case (5) {
                  <app-icon-app-invoice></app-icon-app-invoice>
                }
              }
            </div>

            <mat-card-title>{{ application.applicationName | translate }}</mat-card-title>
            <!-- <img mat-card-image [attr.src]="getLogo(application)" [alt]="application.applicationName" /> -->
          </mat-card-header>
          <mat-card-content>
            {{ application.description }}
          </mat-card-content>
          <mat-card-actions>
            @if ((primaryApp()?.applicationID || -1) !== application.applicationID) {
              <button
                mat-flat-button
                type="button"
                color="accent"
                (click)="makePrimaryApp($event, application.applicationID)"
                [title]="'Click here to make this application your primary entry point in Logic Suite' | translate"
              >
                {{ 'Set primary application' | translate }}
              </button>
            } @else {
              <button mat-flat-button type="button" class="selected">
                <mat-icon>check</mat-icon>
                {{ 'Current primary application' | translate }}
              </button>
            }
          </mat-card-actions>
        </mat-card>
      </a>
    }
  </section>

  <lib-theme-switch *libFeatureFlag="'chameleon/rail'; invert: true" [showLabel]="false"></lib-theme-switch>

  @if (available() < 1) {
    <app-warn type="info">
      <span>
        <span>{{ 'You have no available applications' | translate }}.</span>
        @if (isAdministrator()) {
          <span>
            {{ 'You can set up your' | translate }}
            <a [routerLink]="['/users/applications']" translate>subscriptions here</a>.
          </span>
        }
      </span>
    </app-warn>
  }
}
