import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';

import { CacheModule } from '@logic-suite/shared/cache';

import { TutorialModule } from '../../shared/tutorial';
import { HelpComponent } from './help.component';

@NgModule({
  declarations: [HelpComponent],
  imports: [
    CacheModule,
    CommonModule,
    TutorialModule,
    RouterModule,
    TranslateModule,
    MatCardModule,
    MatListModule,
    MatIconModule,
  ],
})
export class HelpModule {}
