import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ThemeSwitchComponent } from '@logic-suite/shared';
import {
  accentPalette,
  graphPalette,
  greenPalette,
  greyPalette,
  primaryPalette,
  warningPalette,
} from '@logic-suite/shared/utils';

@Component({
  selector: 'app-palette',
  standalone: true,
  imports: [CommonModule, ThemeSwitchComponent],
  templateUrl: './palette.component.html',
  styleUrls: ['./palette.component.scss'],
})
export class PaletteComponent {
  primary = primaryPalette;
  accent = accentPalette;
  warning = warningPalette;
  green = greenPalette;
  grey = greyPalette;
  graph = graphPalette;
}
