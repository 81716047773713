import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TelemetryModule } from '../telemetry/telemetry.module';
import { GlobalErrorHandler } from './error-handler.service';
import { ErrorInterceptor } from './error.interceptor';

@NgModule({
  declarations: [],
  imports: [CommonModule, TelemetryModule, MatSnackBarModule],
  providers: [
    {
      // processes all errors
      provide: ErrorHandler,
      // useClass: getEnv('env') !== 'dev' ? GlobalErrorHandler : ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  exports: [TelemetryModule],
})
export class ErrorHandlerModule {}
