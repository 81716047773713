<h1>Palette</h1>

<div>This page contains the graphic palette used throughout the Logic Suite application</div>
<section>
  <div>
    <header>Primary</header>
    @for (color of primary; track color) {
      <span [ngStyle]="{ 'background-color': color }" [attr.data-col]="color"></span>
    }
  </div>
  <div>
    <header>Accent</header>
    @for (color of accent; track color) {
      <span [ngStyle]="{ 'background-color': color }" [attr.data-col]="color"></span>
    }
  </div>
  <div>
    <header>Warning</header>
    @for (color of warning; track color) {
      <span [ngStyle]="{ 'background-color': color }" [attr.data-col]="color"></span>
    }
  </div>
  <div>
    <header>Success</header>
    @for (color of green; track color) {
      <span [ngStyle]="{ 'background-color': color }" [attr.data-col]="color"></span>
    }
  </div>
  <div>
    <header>Grey</header>
    @for (color of grey; track color) {
      <span [ngStyle]="{ 'background-color': color }" [attr.data-col]="color"></span>
    }
  </div>
  <div>
    <header>Graph</header>
    @for (color of graph; track color) {
      <span [ngStyle]="{ 'background-color': color }" [attr.data-col]="color"></span>
    }
  </div>
</section>
<lib-theme-switch [showLabel]="true"></lib-theme-switch>
