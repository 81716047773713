import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { catchError, Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getEnv } from '../utils';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private snack: MatSnackBar) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(err => {
        if (getEnv('errorLevel') === 'all') {
          this.snack.open(`${err.message}\n\nPlease report this to post@noova.no`, 'close', {
            duration: 5000,
            panelClass: 'error-snack',
          });
        }
        throw err;
      })
    );
  }
}
