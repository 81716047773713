<mat-card>
  <mat-card-header>
    <mat-card-title>{{ 'Help' | translate }}</mat-card-title>
    <a [routerLink]="[returnRoute()]" [queryParams]="returnRouteParams()" mat-icon-button><mat-icon>close</mat-icon></a>
  </mat-card-header>
  <mat-card-content>
    <mat-list>
      @for (group of groups; track group.groupName) {
        <div mat-subheader>{{ group.groupName | translate }}</div>
        @for (video of group.videos; track video.name) {
          <mat-list-item>
            <app-tutorial matListItemIcon [widgetName]="video.componentName" [showAsModal]="true"></app-tutorial>
            <header matListItemTitle>{{ video.name }}</header>
            <p matListItemLine [innerHTML]="video.description"></p>
          </mat-list-item>
        }
      }
    </mat-list>
  </mat-card-content>
</mat-card>
