import { Component, OnDestroy, OnInit, inject, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { LanguageService } from '@logic-suite/shared/i18n';

import { AccessService } from '../../shared/access';
import { Menu, MenuService } from '../../shared/nav/menu';

export interface VideoGroup {
  groupName: string;
  videos: Video[];
}
export interface Video {
  componentName: string;
  name: string;
  description: string;
}

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent implements OnInit, OnDestroy {
  private readonly menu = inject(MenuService);
  private readonly translate = inject(TranslateService);
  private readonly lang = inject(LanguageService);
  private readonly access = inject(AccessService);
  private readonly route = inject(ActivatedRoute);

  groups$ = new BehaviorSubject<VideoGroup[]>([]);
  get groups(): VideoGroup[] {
    return this.groups$.value;
  }
  subscriptions: Subscription[] = [];
  returnRoute = signal('/');
  returnRouteParams = signal({});

  ngOnInit() {
    this.subscriptions.push(
      this.route.queryParams
        .pipe(
          switchMap(() => {
            // Never return to where we already are.
            const params = this.access.getQueryParams();
            if (params.returnRoute) {
              const decodedRoute = decodeURIComponent(params.returnRoute);
              const path = decodedRoute.split('?')[0];
              const query = this.access.unpackQuery(decodedRoute.split('?')[1] ?? '');
              if (!path.startsWith('/config')) this.returnRoute.set(path);
              this.returnRouteParams.set(query);
            }
            return this.menu.getMenu();
          }),
          filter(res => !!res),
          map(items => items as Menu[]),
          map(items => {
            // Create general division
            const groups = [
              {
                groupName: 'General',
                videos: [{ componentName: 'app-general', name: 'Logic Suite', description: '' }],
              },
            ];

            // Add widgets
            items.forEach(i => {
              let group = groups.find(g => g.groupName === i.groupName);
              if (!group) {
                group = {
                  groupName: i.groupName,
                  videos: [],
                };
                groups.push(group);
              }
              group.videos.push({
                componentName: i.componentName,
                name: this.translate.instant(i.componentName),
                description: i.description || '',
              });
            });
            return groups;
          }),
        )
        .subscribe(groups => this.groups$.next(groups)),
    );

    this.subscriptions.push(
      this.lang.onLangChange
        .pipe(
          switchMap((lng: LangChangeEvent) =>
            combineLatest([
              this.lang.loadTranslations('widget').pipe(filter(lng => !!lng)),
              this.groups$.pipe(filter(groups => !!groups.length)),
            ]),
          ),
        )
        .subscribe(([lng, groups]) => {
          groups.forEach(g =>
            g.videos.forEach(
              v =>
                (v.description =
                  (lng as any)[v.componentName.substring('app-'.length)] ||
                  this.translate.instant('No description added yet')),
            ),
          );
        }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
