import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-primary-dialog',
  template: `
    <h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
      {{ 'Choose' | translate }} {{ appName }} {{ 'as your start application?' | translate }}
    </h1>
    <div mat-dialog-content>
      {{ 'This will make' | translate }} {{ appName }} {{ 'your homepage in Logic Suite on this computer' | translate }}
    </div>
    <div mat-dialog-actions>
      <button mat-button [mat-dialog-close]="false">{{ 'No Thanks' | translate }}</button>
      <button mat-button [mat-dialog-close]="true" cdkFocusInitial>{{ 'Yes please' | translate }}</button>
    </div>
  `,
  styles: [
    `
      .mat-mdc-dialog-title {
        background: var(--primary);
        color: var(--buttonText);
      }
    `,
  ],
})
export class AppPrimaryDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<AppPrimaryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public appName: string
  ) {}
}
