import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TelemetryService } from './telemetry.service';

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule],
  providers: [TelemetryService],
})
export class TelemetryModule {}
