import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { CacheModule } from '@logic-suite/shared/cache';

import { WarnComponent } from '../../shared/feedback';

import { ThemeSwitchComponent } from '@logic-suite/shared';
import { IconAppAdviseComponent } from '../../shared/icons/icon-app-advise.component';
import { IconAppEnergyComponent } from '../../shared/icons/icon-app-energy.component';
import { IconAppFacilityComponent } from '../../shared/icons/icon-app-facility.component';
import { IconAppInvoiceComponent } from '../../shared/icons/icon-app-invoice.component';
import { IconAppSenseComponent } from '../../shared/icons/icon-app-sense.component';
import { AppListComponent } from './app-list.component';
import { AppPrimaryDialogComponent } from './app-primary-dialog.component';
import { FeatureFlagDirective } from '@logic-suite/shared/feature-flag/feature-flag.directive';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CacheModule,
    ThemeSwitchComponent,
    TranslateModule,
    MatCardModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatDialogModule,
    WarnComponent,
    IconAppEnergyComponent,
    IconAppAdviseComponent,
    IconAppFacilityComponent,
    IconAppSenseComponent,
    IconAppInvoiceComponent,
    FeatureFlagDirective,
  ],
  declarations: [AppListComponent, AppPrimaryDialogComponent],
})
export class AppListModule {}
