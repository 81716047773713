import { of, Subject } from 'rxjs';
import { computed, effect, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ApplicationStorageService } from '@logic-suite/shared/storage';
import { JsonUtils } from '@logic-suite/shared/utils';

export interface Favorite {
  id: string;
  type: string;
}

interface FavoriteServiceState {
  loaded: boolean;
  favorites: Map<string, Favorite>;
}

/**
 * Handles saving and restoring favorites.
 *
 * Mainly used to favorite things in the hierarchy, but
 * is not limited to that.
 */
@Injectable({ providedIn: 'root' })
export class FavoriteService {
  jsonUtils = inject(JsonUtils);
  applicationStorageService = inject(ApplicationStorageService);
  private favoritesStorageKey = 'favorites';

  // Sources
  // TODO(bjhandeland): This should trigger loading when something else emits.
  private favoritesLoaded$ = this.loadFavorites();
  toggleFavorite$ = new Subject<Favorite>();

  // State
  private readonly state = signal<FavoriteServiceState>({
    loaded: false,
    favorites: new Map(),
  });

  // Selectors
  loaded = computed(() => this.state().loaded);
  favorites = computed(() => this.state().favorites);

  constructor() {
    // Reducers
    this.favoritesLoaded$.pipe(takeUntilDestroyed()).subscribe((favorites) => {
      this.state.update((state) => ({
        ...state,
        favorites: favorites,
      }));
    });

    this.toggleFavorite$.pipe(takeUntilDestroyed()).subscribe((favorite) => {
      this.state.update((state) => {
        const newFavorites = new Map(state.favorites);
        const key = `${favorite.type}:${favorite.id}`;
        if (newFavorites.has(key)) {
          newFavorites.delete(key);
        } else {
          newFavorites.set(key, favorite);
        }
        return { ...state, favorites: newFavorites };
      });
    });

    effect(() => {
      this.saveFavorites();
    });
  }

  private loadFavorites() {
    const favoritesString = this.applicationStorageService.getItem(this.favoritesStorageKey);
    const favorites = this.jsonUtils.safeParse<Map<string, Favorite>>(favoritesString);

    return of(favorites ?? new Map());
  }

  private saveFavorites() {
    const favoritesString = this.jsonUtils.safeStringify(this.favorites());
    this.applicationStorageService.setItem(this.favoritesStorageKey, favoritesString);
  }
}
