import { trigger, transition, animate, keyframes, style } from '@angular/animations';

export const dropdown = trigger('dropdown', [
  transition(':enter', [
    animate(
      '300ms cubic-bezier(.86,.01,.48,.99)',
      keyframes([
        style({ offset: 0, transform: 'translate(0, calc(-100%))' }),
        style({ offset: 1, transform: 'translate(0, 0)' }),
      ])
    ),
  ]),
  transition(':leave', [
    style({ maxHeight: '50vh' }),
    animate('300ms cubic-bezier(.86,.01,.48,.99)', style({ transform: 'translate(0, calc(-100%))' })),
  ]),
]);
