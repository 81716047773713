import { ErrorHandler, Injectable, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TelemetryService } from '../telemetry/telemetry.service';
import { errorToString, getEnv } from '../utils';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private telemetry = inject(TelemetryService);
  private snack = inject(MatSnackBar);

  handleError(error: Error) {
    // Log the error to azure telemetry
    this.telemetry.logError(error);

    // Log the error to the console
    console.error(error);

    // Show the error to the user
    if (getEnv('errorLevel') === 'all') {
      const message = errorToString(error) || 'Undefined client error';
      this.snack.open(message, 'Close', { duration: 5000 });
    }
  }
}
